// Focus Outline Removal
*:focus {
  outline: none;
}

// Body and HTML Base Styles
body,
html {
  color: #333;
  background-color: #ffffff;
  font-family: "Outfit", sans-serif;

  @media (max-width: 1023px) {
    background-color: #f5f3e7;
  }
}

// Navigation Bar
.nav-bar {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.main-nav {
  mix-blend-mode: multiply;
  backdrop-filter: blur(10px);
}

// Wrapper Styles for Different Components
.wrapper,
.wrapperGrid,
.wrapperPage,
.wrapperNav,
.wrapperFooter {
  max-width: 1550px;
  margin: auto;
}
.wrapper {
  // Big Screens
  padding-left: 51px;
  padding-right: 51px;

  // Laptop Screens
  @media (max-width: 1536px) {
    padding-left: 80px;
    padding-right: 80px;
  }

  // Tablet Screens
  @media (max-width: 1280px) {
    padding-left: 40px;
    padding-right: 40px;
  }

  // Tablet Screens
  @media (max-width: 768px) {
    padding-left: 20px;
    padding-right: 20px;
  }
  // Phone Screens
  @media (max-width: 430px) {
    padding-left: 9px;
    padding-right: 9px;
  }
}
.wrapperGrid {
  // Big Screens
  padding-left: 41px;
  padding-right: 41px;

  // Laptop Screens
  @media (max-width: 1536px) {
    padding-left: 80px;
    padding-right: 80px;
  }

  // Tablet Screens
  @media (max-width: 1280px) {
    padding-left: 70px;
    padding-right: 70px;
  }

  // Tablet Screens
  @media (max-width: 768px) {
    padding-left: 40px;
    padding-right: 40px;
  }
  // Phone Screens
  @media (max-width: 430px) {
    padding-left: 23px;
    padding-right: 23px;
  }
}
.wrapperPage {
  padding-left: 160px;
  padding-right: 160px;

  // Laptop Screens
  @media (max-width: 1536px) {
    padding-left: 80px;
    padding-right: 80px;
  }

  // Tablet Screens
  @media (max-width: 1280px) {
    padding-left: 70px;
    padding-right: 70px;
  }

  // Phone Screens
  @media (max-width: 768px) {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.wrapperFooter {
  // Big Screens
  padding-left: 60px;
  padding-right: 60px;

  // Laptop Screens
  @media (max-width: 1536px) {
    padding-left: 80px;
    padding-right: 80px;
  }

  // Tablet Screens
  @media (max-width: 1280px) {
    padding-left: 70px;
    padding-right: 70px;
  }

  // Phone Screens
  @media (max-width: 768px) {
    padding-left: 10px;
    padding-right: 10px;
  }
}
.wrapperNav {
  // Big Screens
  padding-left: 40px;
  padding-right: 40px;

  @media (max-width: 1023px) {
    padding-left: 60px;
    padding-right: 60px;
  }
  @media (max-width: 768px) {
    padding-left: 40px;
    padding-right: 40px;
  }
  @media (max-width: 425px) {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.wrapperPopup {
  position: fixed; /* Changed from relative to fixed */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  text-align: center;
  width: 90%; /* Adjust width as needed */
  max-width: 970px; /* Optional: to prevent it from becoming too wide on large screens */
  border-radius: 3xl;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: for a slight shadow */
  z-index: 1000; /* Ensures it's above other elements */

  @media (max-width: 1023px) {
    max-width: none;
    border-radius: 0px;
    height: 90vh; /* Limit the height to a percentage of the viewport */
    top: 50%; /* Center it vertically */
    border-radius: 10px; /* Adjust border-radius for smaller screens if needed */
  }
}

.fullscreenOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  z-index: 500;
}

// Additonal colours
.gradient-purple {
  background-image: linear-gradient(to right, #8e74e6, #c7bbf2);
}
.gradient-purple-flipped {
  background-image: linear-gradient(to left, #8e74e6, #c7bbf2);
}
.gradient-pink {
  background-image: linear-gradient(to right, #f47fa4, #f9c1d2);
}
.gradient-yellow {
  background-image: linear-gradient(to right, #fcd97e, #fdecc1);
}
.gradient-green {
  background-image: linear-gradient(to right, #44f0c4, #d8f5eb);
}
// Fonts Titles & Headings
h1 {
  font-size: 55px;
  font-family: "Outfit", sans-serif;
  letter-spacing: 0em; /* Updated */
  line-height: 0.97; /* Updated line height */
  font-weight: 600; /* Updated font weight */
}

a,
button {
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 14px;
  font-weight: 600; /* Semi-bold */
  letter-spacing: 0em; /* Consistent with h1 */
  line-height: 1.07; /* Relatively adjusted */
}

li {
  font-family: "Outfit", sans-serif;
  font-size: 16px;
  letter-spacing: 0em; /* Consistent with h1 */
  line-height: 1.12; /* Relatively adjusted */
  font-weight: 400; /* Regular */
}

.tagline {
  font-family: "Outfit", sans-serif;
  font-size: 21px;
  letter-spacing: 0.04em; /* Consistent with h1 */
  line-height: 1.4; /* Relatively adjusted */
  font-weight: 400; /* Regular */
}

.description {
  font-family: "Outfit", sans-serif;
  font-size: 35px;
  letter-spacing: 0em; /* Consistent with h1 */
  line-height: 1.15; /* Relatively adjusted */
  font-weight: 300; /* Regular */
  color: #9f9f9f;
}

h2 {
  font-family: "Outfit", sans-serif;
  font-size: 55px;
  font-weight: 400; /* Medium */
  letter-spacing: -0.03em; /* Consistent with h1 */
  line-height: 0.97; /* Consistent with h1 */
}

.sub-heading {
  font-size: 35px;
  letter-spacing: 0em; /* Consistent with h1 */
  line-height: 1.12; /* Relatively adjusted */
  font-weight: 400; /* Regular */
}
.foot-logo {
  path {
    fill: #fff !important;
  }
}
p {
  font-family: "Outfit", sans-serif;
  font-size: 18px;
  letter-spacing: 0em; /* Consistent with h1 */
  line-height: 1.4; /* Relatively adjusted */
  font-weight: 300; /* Regular */
}

h3 {
  font-family: "Outfit", sans-serif;
  font-size: 55px;
  letter-spacing: -0.03em; /* Consistent with h1 */
  line-height: 0.97; /* Consistent with h1 */
  font-weight: 400; /* Medium */
}

h4 {
  font-family: "Outfit", sans-serif;
  font-size: 25px;
  letter-spacing: 0em; /* Consistent with h1 */
  line-height: 1.15; /* Relatively adjusted */
  font-weight: 600; /* Bold, adjusted to match h1's weight */
}

h5 {
  font-family: "Outfit", sans-serif;
  font-size: 25px;
  font-weight: 500; /* Medium */
  line-height: 60px; /* Specified line height */
  letter-spacing: 0.025em; /* Adjusted character spacing */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  h1,
  h2,
  h3 {
    font-size: 40px; /* Adjusted size */
    line-height: 0.97; /* Maintained line height from h1 */
  }

  h4 {
    font-size: 20px;
    line-height: 1.1; /* Relatively adjusted */
  }

  a,
  p,
  li,
  button,
  .description {
    //font-size: 24px; /* Smaller text for smaller devices */
    line-height: 1.12; /* Relatively adjusted */
  }
}

@media (max-width: 480px) {
  h1,
  h2,
  h3 {
    font-size: 30px; /* Further reduced for very small devices */
    line-height: 0.97; /* Maintained line height from h1 */
  }

  h4 {
    font-size: 18px;
    line-height: 1.1; /* Relatively adjusted */
  }

  a,
  p,
  li {
    font-size: 16px; /* Even smaller text */
    line-height: 1.12; /* Relatively adjusted */
  }
}

.text-colour-yellow {
  strong {
    color: #fcdf91;
  }
}
.text-colour-purple {
  strong {
    color: #8e74e6;
  }
  color: #8e74e6;
}
.text-colour-black {
  color: #333333;
}
.text-colour-green {
  strong {
    color: #44f0c4;
  }
}
.history:nth-of-type(2) {
  .downpipe {
    display: none;
  }
}
// Images
.image-containeer {
  width: 100%;
  height: 100%;
  object-fit: cover; /* This makes sure your image covers the area without stretching */
}
.header-container {
  width: 100%;
  height: 875px;
  object-fit: cover;

  @media (max-width: 950px) {
    height: 750px;
  }
  @media (max-width: 768px) {
    height: 100%;
  }
}

.image-container-services {
  position: relative;
  width: 100%; /* Container takes the full width of its parent */
  /* Use padding to maintain aspect ratio; 363/478 = 0.7598 (approx. 76%) */
  padding-top: 75.98%;
}

.image-container-services img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; /* Image takes the full width of the container */
  height: 100%; /* Image takes the full height of the container */
  object-fit: cover; /* Ensures the image covers the container without losing its aspect ratio */
  border-radius: 2xl; /* Tailwind's utility for rounded corners, use 'border-radius: 16px;' as a fallback */
}

.first-image {
  opacity: 0.5; /* First image has reduced opacity */
  z-index: 10; /* Ensures the first image is above the second */
}

// Layout
.centered-content {
  display: flex;
  justify-content: center; /* Aligns content horizontally center */
  align-items: center; /* Aligns content vertically center */
  flex-direction: column;
  text-align: center;
  width: 100%; /* Make sure the container has a height */
}
.overlayTextCenter {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  /* Other styles */
}
// List Button
#list {
  list-style-type: none;
  padding: 0;
}
#list li {
  margin: 5px 0;
}

// image, vids and shapes layout
.mediaBlock {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(3, 1fr);

  // Mobile
  @media (max-width: 425px) {
    grid-template-columns: repeat(4, 93px);
    grid-template-rows: repeat(3, 93px);
  }
}

.div1 {
  grid-area: 1 / 1 / 2 / 2;
}
.div2 {
  grid-area: 1 / 2 / 2 / 3;
}
.div3 {
  grid-area: 1 / 3 / 2 / 4;
}
.div4 {
  grid-area: 1 / 4 / 2 / 5;
}
.div5 {
  grid-area: 2 / 1 / 3 / 2;
}
.div6 {
  grid-area: 2 / 2 / 3 / 3;
}
.div7 {
  grid-area: 2 / 3 / 3 / 4;
}
.div8 {
  grid-area: 2 / 4 / 3 / 5;
}
.div9 {
  grid-area: 3 / 1 / 4 / 2;
}
.div10 {
  grid-area: 3 / 2 / 4 / 3;
}
.div11 {
  grid-area: 3 / 3 / 4 / 4;
}
.div12 {
  grid-area: 3 / 4 / 4 / 5;
}

/* Media query for mobile devices */
@media (max-width: 425px) {
  .mediaBlock {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(6, 1fr);
  }

  .div1 {
    grid-area: 1 / 1 / 2 / 2;
  }
  .div2 {
    grid-area: 1 / 2 / 2 / 3;
  }
  .div3 {
    grid-area: 2 / 1 / 3 / 2;
  }
  .div4 {
    grid-area: 2 / 2 / 3 / 3;
  }
  .div5 {
    grid-area: 3 / 1 / 4 / 2;
  }
  .div6 {
    grid-area: 3 / 2 / 4 / 3;
  }
  .div7 {
    grid-area: 4 / 1 / 5 / 2;
  }
  .div8 {
    grid-area: 4 / 2 / 5 / 3;
  }
  .div9 {
    grid-area: 5 / 1 / 6 / 2;
  }
  .div10 {
    grid-area: 5 / 2 / 6 / 3;
  }
  .div11 {
    grid-area: 6 / 1 / 7 / 2;
  }
  .div12 {
    grid-area: 6 / 2 / 7 / 3;
  }
}

/* Media query for medium-sized devices */
@media (min-width: 426px) and (max-width: 1224px) {
  .mediaBlock {
    grid-template-columns: repeat(3, 1fr);
  }

  .div4,
  .div8,
  .div12 {
    display: none;
  }

  /* Adjust grid areas if necessary for your design */
  /* Example adjustments, assuming reflow without the 4th column:
  .div1 { grid-area: 1 / 1 / 2 / 2; }
  .div2 { grid-area: 1 / 2 / 2 / 3; }
  .div3 { grid-area: 1 / 3 / 2 / 4; }
  .div5 { grid-area: 2 / 1 / 3 / 2; }
  .div6 { grid-area: 2 / 2 / 3 / 3; }
  .div7 { grid-area: 2 / 3 / 3 / 4; }
  .div9 { grid-area: 3 / 1 / 4 / 2; }
  .div10 { grid-area: 3 / 2 / 4 / 3; }
  .div11 { grid-area: 3 / 3 / 4 / 4; }
  */
}

// Contact page
@media (max-width: 768px) {
  .yellowbar {
    display: none;
  }
  .column-1,
  .column-4 {
    display: none; /* This will hide the first and fourth columns on small screens */
  }
}

.responsive-background {
  background-color: #f5f3e7;

  @media (max-width: 768px) {
    background-color: white; /* Change to bg-white on small screens */
  }
}
.responsive-contact {
  grid-template-columns: repeat(4, 1fr); /* 4 columns on large screens */
  grid-row-gap: 50px;
}
@media (max-width: 1300px) {
  /* LG breakpoint */
  .responsive-contact {
    grid-template-columns: repeat(2, 1fr); /* 4 columns on large screens */
  }
}

// Contact Grid
.form-parent {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr) 2fr;
  grid-column-gap: 16px;
  grid-row-gap: 0px;
}

.contact1 {
  grid-area: 1 / 1 / 2 / 2;
}
.contact2 {
  grid-area: 1 / 2 / 2 / 3;
}
.contact3 {
  grid-area: 2 / 1 / 3 / 2;
}
.contact4 {
  grid-area: 2 / 2 / 3 / 3;
}
.contact5 {
  grid-area: 3 / 1 / 4 / 3;
}

.name-field,
.email-field,
.number-field,
.message-field {
  width: 100%; /* Make textarea full-width */
  padding: 14px; /* Adjust textarea padding */
  border: 1px solid #9f9f9f; /* Change textarea border color */
  border-radius: 11px; /* Add border radius for rounded corners */
  background: #00000000;
  box-sizing: border-box; /* Include padding and border in width/height */
  resize: vertical; /* Allow vertical resizing */
  /* Add any other textarea styles here */
}
.error-indicator {
  display: none;
  color: red;
  margin-left: 8px;
}

.error-indicator.active {
  display: inline;
  content: "*";
}

/* Custom checkbox style */
.checkbox {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin-top: 0px;
  margin-bottom: -4px;
  width: 20px; /* Width of the checkbox */
  height: 20px; /* Height of the checkbox */
  background: transparent; /* Transparent background */
  border: 1px solid #9f9f9f; /* Black border */
  border-radius: 5px;
  cursor: pointer; /* Cursor changes to a pointer when hovering over the checkbox */
}

/* Optional: custom style for the checkmark to make it more visible */
.checkbox:checked:after {
  content: "✔";
  color: #9f9f9f;
  position: absolute;
  font-size: 18px;
}
.trial-parent {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(
    3,
    100px
  ); /* Change 100px to your desired height */
  grid-column-gap: 16px;
  grid-row-gap: 0px;
  @media (max-width: 1023px) {
    display: block;
  }
}

.trial1 {
  grid-area: 1 / 1 / 2 / 2;
}
.trial2 {
  grid-area: 1 / 2 / 2 / 3;
}
.trial3 {
  grid-area: 2 / 1 / 3 / 2;
}
.trial4 {
  grid-area: 2 / 2 / 3 / 3;
}
.trial5 {
  grid-area: 3 / 1 / 4 / 2;
}
.trial6 {
  grid-area: 3 / 2 / 4 / 3;
}
// Shapes
.right-angle-bottom-left {
  position: relative;
  width: 0;
  border-right: 10vw solid transparent; /* Adjust the vw value as needed */
  border-bottom: 11vw solid #44f0c4; /* Maintain aspect ratio */
}

.right-angle-bottom-right {
  position: relative;
  width: 0;
  border-left: 10vw solid transparent; /* Adjust the vw value as needed */
  border-bottom: 11vw solid #fcd97e; /* Maintain aspect ratio */
}

.curved-triangle {
  width: 0;
  height: 0;
  border-left: 5vw solid transparent; /* Adjust the vw value as needed */
  border-bottom: 5vw solid #fcd97e; /* Smaller triangle */
  position: relative;
}
.rounded-top-left {
  border-top-left-radius: 200px; /* Adjust the radius as needed */
}
.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black; /* This gives a cute underline effect */
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: auto;
  background-color: white; /* Pure white, like our intentions */
  text-align: center;
  border-radius: 10px; /* Here's your rounded 3XL moment */
  padding: 10px; /* A little padding never hurt anyone */

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  opacity: 0; /* Start invisible */
  transition: opacity 0.3s, visibility 0s linear 0.3s; /* Delay the visibility transition */
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1; /* Full visibility */
  transition-delay: 0s; /* Remove the delay when hovering */
}

//  drop down
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #1f2937;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

/* Dropdown items */
.dropdown-content a {
  color: white;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Show the dropdown */
.dropdown:hover .dropdown-content {
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  background-color: #374151;
}

// hidden class
.tabHide {
  display: block;
  @media (max-width: 1123px) {
    display: none;
  }
}
.mobHide {
  display: block;
  @media (max-width: 430px) {
    display: none;
  }
}
.aboveTabHide {
  display: none;
  @media (max-width: 1123px) {
    display: block;
  }
}

.tabletHidden {
  display: block; /* Show content by default */

  @media (min-width: 700px) and (max-width: 850px) {
    display: none; /* Hide content between 700 and 850 pixels */
  }
}
.laptopHidden {
  display: block; /* Show content by default */

  @media (min-width: 1024px) and (max-width: 1440px) {
    display: none; /* Hide content between 700 and 850 pixels */
  }
}

// List button
.toggleContent {
  padding: 10px;
  margin-top: 10px;
  border-radius: 5px;
  @media (max-width: 1023px) {
    border: 1px solid #ffffff00;
  }
}

strong {
  font-weight: 600;
}

.font-bold-custom {
  font-weight: 500;
}
.no-border {
  border: none;
}
.hidden {
  display: none;
}

.mobileNavLogo {
  max-width: 65px;
  @media (max-width: 433px) {
    max-width: 50px;
  }
}

.mob-nav {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  max-width: 500px;
  right: 0;
  .mob-menu {
    transform: translateX(100%);
  }
  @media (max-width: 780px) {
    width: 100%;
  }
}
.mob-nav.open {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
  .mob-menu {
    transform: translateX(0%);
  }
  @media (max-width: 780px) {
    width: 100%;
  }
}
.mob-nav.open {
  opacity: 1;
}

.text-black.w-full.flex.mt-\[-45px\].justify-center {
  padding-top: 15px;
  padding-bottom: 13px;
}

.banner-text {
  font-size: 15px;
  letter-spacing: -0.1px;
}

.link-text {
  letter-spacing: 0px;
}

/* Existing SVG transition */
[class^="svg-color-change"] {
  transition: fill 1s ease-in-out; /* Smooth transition for the fill color over 1 second */
}

/* Add transition for div background colors */
.div-color-change {
  transition: background-color 1s ease-in-out; /* Smooth transition for background color over 1 second */
}

button.flickity-button.flickity-prev-next-button.next,
button.flickity-button.flickity-prev-next-button.previous {
  margin-top: 320px;
}

/* Base style for labels */
.trial-parent label {
  transition: all 0.3s ease; /* Smooth transition for size and position changes */
  font-size: 16px; /* Example base font size */
  /* Other base styles for label */
}
.textarea-parent label {
  transition: all 0.3s ease; /* Smooth transition for size and position changes */
  font-size: 16px; /* Example base font size */
  /* Other base styles for label */
}
.textarea-parent label.shrink {
  font-size: 12px; /* Smaller font size */
  color: #555; /* Optional: change color */
  top: 0 !important;
}
/* Style for labels when the 'shrink' class is added */
.trial-parent label.shrink {
  font-size: 12px; /* Smaller font size */
  color: #555; /* Optional: change color */
  top: 0 !important;
}
.content-slider .slide {
  display: none;
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.content-slider .slide.active {
  display: block;
  opacity: 1;
}
.slider-controls {
  width: 100%;
}

button#prevBtn {
  background: white;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  position: relative;
  svg {
    width: 20px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
button#nextBtn {
  background: white;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  position: relative;
  svg {
    width: 20px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
.full-img:after {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(transparent),
    color-stop(80%, rgba(0, 0, 0, 0.7))
  );
  background: linear-gradient(180deg, transparent, rgba(0, 0, 0, 0.7) 80%);
  bottom: 0;
  content: "";
  height: 50%;
  left: 0;
  position: absolute;
  width: 100%;
}
.contact-deets {
  p {
    font-size: 16px !important;
  }
  a {
    font-size: 16px;
  }
}
